

import {Component, Vue} from "vue-property-decorator";
import MtsTebligatGonderList from "@/components/lists/MtsTebligatGonderList.vue";
import UyapBagliDegilView from "@/views/eicrapro/UyapBagliDegilView.vue";

@Component({
  components: {UyapBagliDegilView, MtsTebligatGonderList}
})
export default class MtsTebligatView extends Vue {
}
