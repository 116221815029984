
import {Component, Vue} from "vue-property-decorator";
import {TamamlanmayanDosyalarRow, TamamlanmayanDosyaTurKod} from "@/plugins/uyap-plugin/uyap/TamamlanmayanDosyalar";
import {DataTableHeader} from "vuetify";
import {Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";

@Component({
  components: {}
})
export default class MtsTebligatGonderList extends Vue {
  filterStr = '';
  headers: DataTableHeader[] = [
    {text: "Birim Adı", align: "start", sortable: true, value: "birimAdi"},
    {text: "Dosya Esas Numarası", align: "start", sortable: true, value: "dosyaNo", filterable: true},
    {text: "Açılış Tarihi", value: "dosyaAcilisTarihi", sortable: true},
    {text: "Son İşlem Tarihi", value: "sonIslemTarihi"},
    {text: "Taraflar", value: "taraflar", filterable: true},
  ];
  selectedItems = [];
  selectableItems: Array<TamamlanmayanDosyalarRow> = [];
  onFly = false;

  async created() {
    try {
      let tamamlanmayanlar = await this.$uyap.TamamlanmayanDosyalar().run({
        dosyaTurKod: TamamlanmayanDosyaTurKod.MTS
      });
      this.selectableItems = tamamlanmayanlar.filter(x => (x.surecNo == 5 && x.tebligatGonderimAktif != 'H'));//Tebligat gonderilebilecek dosyalar
    } catch (e) {
      this.$toast.error(e.message);
    }
  }

  async createTask() {
    this.onFly = true;
    if (this.selectedItems.length > 0) {
      let tasks: Array<Task> = [];
      this.selectedItems.forEach((item: any) => {
        let task: Task = new Task();
        task.dosya_esas_no = item.dosyaNo
        task.birim_adi = item.birimAdi
        task.task_type_id = TaskTuru.mts_tebligat_gonder
        task.data = {
          adresTuru: "ADRTR00008",
          dosya: {
            dosyaNo: item.dosyaNo,
            dosyaId: item.dosyaID,
            taraflar: item.taraflar
          }
        }
        tasks.push(task);
      });
      await this.$store.dispatch('postTasks', tasks);
    }
    this.onFly = false;
  }
}
